import * as React from 'react';
import styled from 'styled-components';
import about from '@/cms/about';
import { Box, Flex, Text, BoxProps } from 'rebass/styled-components';
import { Title } from '@/components/Title';
import { styledTheme } from '@/styledTheme';
import { ContentWrapper } from './ContentWrapper';

export const Team = (wrapperProps: BoxProps) => (
  <ContentWrapper
    maxWidth={1000}
    mt={[5]}
    {...wrapperProps}
    mb={['45px', '45px', '90px']}
  >
    <Title mb={['25px', '25px', '40px']}>{about.team.title}</Title>
    <ItemsWrapper flexWrap="wrap" mx={[-2]}>
      {about.team.items.map((i) => (
        <Box width={[1, 1, 1 / 3]} p={[2]}>
          {i.img}
          <Text
            mt={['26px', '26px', 3]}
            fontSize="18px"
            fontWeight="600"
            color={styledTheme.colors.primary}
            fontFamily={styledTheme.fonts.heading}
          >
            {i.name}
          </Text>
          <Text
            fontSize={['14px', '14px', 2]}
            color={styledTheme.colors.secondary}
            fontFamily={styledTheme.fonts.heading}
            lineHeight="19px"
            mt={['10px', '10px', 1]}
            mb={[3, 3, 2]}
          >
            {i.title}
          </Text>
        </Box>
      ))}
    </ItemsWrapper>
  </ContentWrapper>
);

const ItemsWrapper = styled(Flex)`
  > :nth-child(2n) {
    margin-left: 70px;
  }

  img {
    display: block;
    width: 280px;
    height: 280px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    > :nth-child(2n) {
      margin-left: 0;
    }
    img {
      display: block;
      width: 216px;
      height: 216px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    > :nth-child(2n) {
      margin-left: 0;
    }
    img {
      display: block;
      width: 280px;
      height: 280px;
    }
  }
`;
