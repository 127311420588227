import React from 'react';
import { Layout } from '@/components/Layout';
import data from '@/cms/about';
import { Title } from '@/components/Title';
import { Row } from '@/components/Row';
import { Clients } from '@/components/Clients';
import { CTA } from '@/components/CTA';
import { Team } from '@/components/Team';
import { Text } from '@/components/Text';
import { WhatWeDo } from '@/components/home/WhatWeDo';

export default () => (
  <Layout>
    {/* <Row */}
    {/*  mt={['90px', '90px', 5]} */}
    {/*  left={<Title>{data.about.title}</Title>} */}
    {/*  right={<Text fontSize={[3]}>{data.about.text}</Text>} */}
    {/* /> */}

    <Row
      mt={['90px', '90px', 5]}
      left={<Title>{data.ourApproach.title}</Title>}
      right={<Text fontSize={[3]}>{data.ourApproach.text}</Text>}
    />

    <Team mb={['90px', '90px', 5]} />

    <WhatWeDo />

    <Clients />

    <CTA />
  </Layout>
);
