import React from 'react';
import home from '@/cms/home';
import { StaticImage } from 'gatsby-plugin-image';

export const about = {
  // about: {
  //   title: 'about',
  //   text:
  //     'We are a group of designers, developers and analysts, who share the passion for data and technology. Combining our experience in design, software engineering and data science we create effective data-based experiences. We lead the full process of creative projects, on every level and stage.',
  // },
  ourApproach: {
    title: 'our approach',
    text:
      'We are a group of designers, developers and analysts, who share the passion for data, technology and problem-solving. We specialize in making numbers understandable and attractive. For this reason, we care about every aspect of the visualization – from data accuracy and ease of maintenance, to high quality design and seamless usability – to turn your data into physical or digital artworks and tools.',
  },
  team: {
    title: 'core team',
    items: [
      {
        name: 'Hanna Piotrowska',
        title: 'Cofounder / Head of Design',
        img: (
          <StaticImage
            src="../../static/team/hanna.jpg"
            alt="Hanna head of design"
            placeholder="tracedSVG"
            quality={90}
          />
        ),
      },
      {
        name: 'Mateusz Sawka',
        title: 'Cofounder / CEO',
        img: (
          <StaticImage
            src="../../static/team/mateusz.jpg"
            alt="Mateusz head of development"
            placeholder="tracedSVG"
            quality={90}
          />
        ),
      },
      {
        name: 'Igor Pasieczny',
        title: 'Cofounder / Intergalactic Code Emperor',
        img: (
          <StaticImage
            src="../../static/team/igor.jpg"
            alt="Igor full stack developer"
            placeholder="tracedSVG"
            quality={90}
          />
        ),
      },
      // {
      //   name: 'Grzegorz Głowienko',
      //   title: 'Data Scientist',
      //   img: (
      //     <StaticImage
      //       src="../../static/team/grzegorz.jpg"
      //       alt="Grzegorz full stack developer"
      //       placeholder="tracedSVG"
      //       quality={90}
      //     />
      //   ),
      // },
      {
        name: 'Tomasz Sokołowski',
        title: 'Data Engineer',
        img: (
          <StaticImage
            src="../../static/team/tomasz.jpg"
            alt="Tomasz data engineer"
            placeholder="tracedSVG"
            quality={90}
          />
        ),
      },
    ],
  },
};

export default about;
